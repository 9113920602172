import styled from "styled-components";
import tw from 'twin.macro';

export const BackgroundStyles = styled.div`
    ${tw`relative`}
    ::before {
        content: "";
        ${tw`bg-white absolute w-screen h-full right-1/2 transform translate-x-1/2 lg:right-0 lg:translate-x-0`}
    }
`
