import React, { useState } from "react"
import { navigate } from "@reach/router" 
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import { buildFluidImageData } from '@imgix/gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
// Layout
import Layout from "../components/Layout"
import PageBanner from '../components/PageBanner'

// Tailwind React UI Components
import { 
  Container,
  Section
} from 'tailwind-react-ui'

// Search
import { BackgroundStyles } from "../styles/BackgroundStyles";
import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  Hits,
  Pagination,
  Configure
} from 'react-instantsearch-dom'

import PageHit from '../components/Refinement/PageHit'
import SearchBox from "../components/Refinement/SearchBox";
import SearchPageResultStyles from "../styles/SearchPageResultStyles";
import { NextPage, PreviousPage } from "../components/Search/Pagination";
import { createURL, urlToSearchState } from '../utils/createUrl'

const DEBOUNCE_TIME = 400;
const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const SearchIndex = ({location }) => {

  const data = useStaticQuery(graphql`
    query {
      siteInfo: craftSiteInformationGlobalSet {
        ...SiteInfoFragment
      }
    }
  `)
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const [debouncedSetState, setDebouncedSetState] = useState(null);

  const onSearchStateChange = updatedSearchState => {
    clearTimeout(debouncedSetState);

    setDebouncedSetState(
      setTimeout(() => {
          navigate(`${location.pathname}${createURL(updatedSearchState)}`, {
            state: updatedSearchState
          })
      }, DEBOUNCE_TIME)
    );

    setSearchState(updatedSearchState);
  };

	const siteInfo = data.siteInfo
	const metaImage = siteInfo.siteImage[0] || null

	const metaImageFormatted = buildFluidImageData(
		`${metaImage.url}`,
		{
			ar: 1, // required
			auto: ['format', 'compress'],
			sizes: `300px, 300px`, // optional, but highly recommended
			width: 300,
			height: 300

		}
    )

  // TODO: update styles on search page
  // https://tablemountain.net/search?query=tma
  return (
		<Layout>
      		<Helmet 
				title = "Search The Table Mountain Aerial Cableway Car Website"
				description = {siteInfo.siteDescription}
				meta = {[
					{ name: `description`, content: siteInfo.siteDescription },
					{ itemProp: `description`, content: siteInfo.siteDescription },
					{ itemProp: `image`, content: metaImageFormatted.src },
					{ property: `og:title`, content: siteInfo.siteTitle	},
					{ itemProp: `og:url`, content:  "https://www.tablemountain.net/search" },
					{ property: `og:description`, content: siteInfo.siteDescription	},
					{ property: `og:image`, content: metaImageFormatted.src	},
					{ property: `article-title`, content: siteInfo.siteTitle },
					{ name: `twitter:card`, content: "summary" },
					{ name: `twitter:image`, content: metaImageFormatted.src },
					{ name: `twitter:image:alt`, content: metaImage.imageAlt },
					{ name: `twitter:title`, content: siteInfo.siteTitle },
					{ name: `twitter:description`, content: siteInfo.siteDescription }
				]}
			/>
			<Helmet>
				<link rel="image_src" href={`${metaImageFormatted.src}`} />
				<link rel="canonical" href="https://www.tablemountain.net/search" />
			</Helmet>
      <Section p="0" className="relative">
				<PageBanner
					title    = "Search"
					subtitle = "Search The Table Mountain Aerial Cableway Car Website"
					image    = {metaImage}
          padding  = "py-24" 
				/>
			</Section>	
		<Section p="0" className="relative">
        <Container className="pt-8 pb-14 xl:w-3/4">
          <div className="w-full mx-auto h-full lg:flex">
            <BackgroundStyles className="w-full">

              <div className="relative pb-8">
              {!searchState.query && (
                <form className="flex items-center mb-3" method="GET" action="/search">
                  <input
                  className="bg-transparent border border-blue rounded-full flex-1 pl-5 pr-8 pl-lg-8 focus:ring-0 p-3 block z-10 mr-3 "
                  type="text"
                  placeholder="Search this website"
                  aria-label="Search the Table Mountain Aerial Cableway website" name="query" />
                  <button className="text-secondary" type="submit"><FontAwesomeIcon size="lg" icon={faSearch} alt="faSearch website icon text-secondary" aria-label="Search entire website" className="block" /></button>
                </form>
              )}
              {searchState.query && (
                <InstantSearch
                    searchClient        = {searchClient}
                    indexName           = "Global"
                    searchState         = {searchState}
                    onSearchStateChange = {onSearchStateChange}
                    createURL           = {createURL}
                  >
                    <Configure hitsPerPage={8} />
                  
                    
                    <SearchBox />
                    
                        <SearchPageResultStyles>
                            <Hits hitComponent={PageHit} />
                            <Pagination 
                                showFirst    = {false}
                                showLast     = {false}
                                translations = {{
                                previous: (<PreviousPage />),
                                next    : (<NextPage />)
                            }} />
                        </SearchPageResultStyles>
                    
                </InstantSearch>
                )}

              </div>
            </BackgroundStyles>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default SearchIndex