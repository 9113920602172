import React from "react";
import { Link } from '@3nvi/gatsby-theme-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'

export const NextPage = () => {
    return (
      <>Next&nbsp;&nbsp; <FontAwesomeIcon size="2x" className={`w-5 h-5`} icon={faChevronRight} alt="Next icon" aria-label="Go to next page" /></>
    ) 
}

export const PreviousPage = () => {
    return (
      <><FontAwesomeIcon size="2x" className={`w-5 h-5`} icon={faChevronLeft} alt="Previous icon" aria-label="Go to previous page" /> &nbsp;&nbsp;Previous</>
    ) 
}
  
const Pagination = ({ prevUrl, nextUrl, totalPages, currentPage, base, className='' }) => {

    return (
        <nav className={`${className}`} role="navigation" aria-label="Pagination Navigation">
            <Link to={prevUrl} 
                className={`mr-auto uppercase text-primary flex items-center ${prevUrl ? '' : 'pointer-events-none opacity-0'}`}>
                <PreviousPage />
            </Link>
            
            <div className="rounded-full text-sm text-primary border overflow-hidden shadow-sm bg-white">
                {Array.from({ length: totalPages }).map((_, i) => (
                    <Link
                        className={`text-sm hover:text-secondary px-2 border-r ${i === 0 ? 'border-l' : ''} leading-none border-primary ${currentPage === 1 && i === 0 ? 'text-primary font-display' : 'text-gray-400'}`} activeClassName="text-primary font-display"
                        to={`${base}${i > 0 ? '/p' : ''}${i > 0
                            ? i + 1
                            : ''}`}
                        key={`page${i}`}
                    >
                        {i + 1}
                    </Link>
                ))}
            </div>

            <Link to={nextUrl} 
                className={`mr-auto uppercase text-primary flex items-center ${nextUrl ? '' : 'pointer-events-none opacity-0'}`}>
                <NextPage />
            </Link>
        </nav>
    );
}

export default Pagination;