import qs from 'qs'
const createURL = state => {
    const isDefaultRoute =
      !state.query &&
      state.page === 1 ;
  
    if (isDefaultRoute) {
      return '';
    }
  
    const queryParameters = {};
  
    if (state.query) {
      queryParameters.query = state.query;
    }

    if (state.page !== 1) {
      queryParameters.page = state.page;
    }
   
  
    const queryString = qs.stringify(queryParameters, {
      addQueryPrefix: true,
      arrayFormat: 'repeat',
    });
  
    return `${queryString}`;
  };

  const urlToSearchState = ({ search }) => qs.parse(search.slice(1));

  export {createURL, urlToSearchState};