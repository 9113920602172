import styled from "styled-components"

import tw from 'twin.macro'

export default styled.div`

  .ais-Hits-list {
      ${tw`mx-auto my-10 xl:w-4/5`}
  }

  .ais-Pagination--noRefinement {
    display: none;
  }

  .ais-Pagination {
    ${tw`p-3 flex mx-auto text-center text-sm`}
  }
  .ais-Pagination-list {
    ${tw`mx-auto py-0 rounded-full text-primary border overflow-hidden shadow-sm bg-white flex`}
  }
  .ais-Pagination-item--previousPage {
    ${tw``}
  }
  .ais-Pagination-item--nextPage {
    ${tw``}
  }
  .ais-Pagination-item--previousPage,
  .ais-Pagination-item--nextPage {
    a {
      ${tw`text-primary uppercase text-sm`}
    }
  }
  .ais-Pagination-item--disabled {
    opacity:.3 !important;
  }

  .ais-Hits-item {
      ${tw`py-4 divide-y my-5`}
  }

  .ais-Pagination-link {
    ${tw`relative inline-flex items-center px-4 py-2 border-t-0 border-b-0 border-l border-gray-200 border-r-0 hover:text-gray-500 transition ease-in-out duration-150 leading-7 h-full active:bg-primary active:text-white focus:bg-primary focus:text-white hover:bg-gray-200`}
  }
  .ais-Pagination-item--previousPage .ais-Pagination-link {
    ${tw`border-l-0`}
  }

  .ais-SortBy-select {
    ${tw`border-0 py-0 ring-0`}
  }

  .ais-Pagination-link--selected {
    ${tw`bg-primary text-white font-black`}
  }
  
  .ais-Snippet-highlighted,
  .ais-Highlight-highlighted {
    ${tw`bg-secondary text-primary`}
  }
  .ais-PoweredBy {
    display: flex;
    justify-content: flex-end;
    font-size: 80%;

    svg {
      width: 70px;
    }

    path[fill="#FFFFFF"] {
      ${tw`fill-current`}
    }
  }
`