import React from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'

// import { useTranslation }   from "@3nvi/gatsby-theme-intl"
import { connectSearchBox } from "react-instantsearch-dom"

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => {
   
    // const { t } = useTranslation();
      return (
        <label htmlFor="searchInput" className={`${className} w-full`}>
          <span className="sr-only">Search Table Mountain Cableway Website</span>
          <div className="relative w-full">
            <div className="flex flex-wrap justify-between items-stretch relative w-full xl:w-3/4 my-10 mx-auto">
                <input 
                  id="searchInput"
                  name="searchInput"
                  type="text"
                  placeholder="Search"
                  aria-label="Enter text into this field to list search result for the entire website"
                  onChange={e => refine(e.target.value)}
                  value={currentRefinement}
                  onFocus={onFocus}
                  className="bg-transparent border border-blue rounded-full flex-1 pl-5 pr-8 pl-lg-8 focus:ring-0 p-3 block z-10"/>
                <FontAwesomeIcon size="lg" icon={faSearch} alt="faSearch website icon" aria-label="Search entire website" className="block absolute top-3 right-5 z-0" />
            </div>
          </div>
        </label>
      )
  }
)