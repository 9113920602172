import React from "react"
import {
	Box,
	Card,
	CardBody,
	FillButton,
	Title,
} from "tailwind-react-ui";
import { Highlight } from "react-instantsearch-dom"
import CustomSnippet from "../CustomSnippet";
// import { func } from "prop-types";

const PageHit = ({ hit }) => {
	const array = { "aboutUsPages": "About us", "blog": "Our blog", "ourBlog": "Our blog", "classInTheClouds": "Class in the Clouds", "comeExplorePages": "Come explore", "healthAndSafety": "Health and Safety", "operatingHours": "Operating hours", "planYourVisitPages": "plan your visit", "talkToUs": "Talk to us" };
	
	function mapHandle(handle){
      for (var key in array) {
       if(key === handle){
          return array[key];
       }
      }
    }
    const translate = mapHandle(hit.typeHandle);

	return (
		<Card className="mx-auto mb-5 xl:w-3/4">
			{/* <Box className="hidden lg:block w-full md:w-1/3 overflow-hidden">
				<ImgixFluidImage attribute="image" className="w-full bg-gray-light relative block rounded-lg mt-1" imageUrl={`/${hit.uri}`} ar={2/1.67}  sizes={`(min-width: 700) 520px, 720px`} /> 
			</Box> */}
			<Box>
				<Title level={6} font="heading" leading="snug" className="text-primary md:text-3xl"><Highlight attribute="title" hit={hit} tagName="mark" /></Title>
				<CustomSnippet attribute="summary" hit={hit} tagName="mark" className="text-primary leading-normal focus:shadow-outline line-clamp-2" />
			</Box>
			<CardBody wrap className="flex items-baseline px-0 pt-5 mt-auto">
				<FillButton font="display" data-type="button" bg="white" text="primary" text-hocus="white" is="a" href={`/${hit.uri}`} className="btn border-primary focus:border-primary px-3 lg:px-8 py-3 rounded-full whitespace-nowrap mr-3 my-0 focus:bg-primary">
					<span className="block relative">Read more</span>
				</FillButton>
				{translate ?
					<FillButton font="display" data-type="button" bg="gray-light" text="gray-500" is="a" href={`/${translate.uri}`} leading="none" className="btn border text-xs px-2 py-0 rounded-full whitespace-nowrap mr-2 mt-2">
						<small className="block relative">{translate}</small>
					</FillButton>
				: null}
			</CardBody>
		</Card>
	)
}

export default PageHit